<template>
  <div class="users">
    <div class="container">
      <div class="Main">
        <div class="mb">
          <span>个人信息</span>
        </div>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="280px"
          class="user-ruleForm"
          :label-position="labelPosition"
        >
          <el-form-item label="真实姓名:" prop="userName">
            <el-input
              v-model="ruleForm.userName"
              placeholder="请输入您的真实姓名"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别:" required>
            <el-radio-group v-model="ruleForm.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item ref="logo" label="上传头像:">
            <!-- <el-upload
              class="avatar-uploader"
              action=""
              :limit="1"
              :file-list="fileList"
              :http-request="uploadRequest"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                图片大小最好在200 X200，支持扩展名：.png .jpg .jpeg...
              </div>
            </el-upload> -->
            <CropperImage
              ref="CropperImage"
              v-model="ruleForm.headPic"
              :upload-pictures="$refs.logo"
            ></CropperImage>
            <div class="el-upload__tip">
              图片大小最好在200 X200，支持扩展名：.png .jpg .jpeg...
            </div>
          </el-form-item>
        </el-form>
        <div class="commitbtn">
          <a @click="handleMakeSure">确定提交</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CropperImage from './components/CropperImage'
import { uploadimage } from '@/api/create'
import { userRealAuth } from '@/api/login'
import to from 'await-to'
import { setCookie } from '@/utils/cookies'
import { mapState } from 'vuex'
export default {
  components: {
    CropperImage,
  },
  data() {
    return {
      ruleForm: {
        username: '',
        sex: '1',
      },
      labelPosition: 'right',
      rules: {
        userName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { min: 0, max: 24, message: '长度不得大于20字符', trigger: 'blur' },
        ],
      },
      fileList: [],
      imageurl: '',
      loading: false,
      img: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  mounted() {
    if (JSON.stringify(this.userInfo) !== '{}') {
      this.ruleForm = this.userInfo
      this.imageurl = this.userInfo.headPic
    }
  },
  methods: {
    uploadRequest(file) {
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      uploadimage(params).then(res => {
        if (res.msg == 'success') {
          this.imageurl = res.data.objectUrl
          this.fileList.push({ url: res.data.objectUrl })
        }
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    async handleMakeSure() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          console.log(this.ruleForm.headPic)
          this.loading = true
          const [, err] = await to(
            userRealAuth({
              realName: this.ruleForm.userName,
              ...this.ruleForm,
            }),
          )
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          setCookie('headPic', this.imageurl) //头像
          setCookie('nickName', this.ruleForm.userName) //昵称
          setCookie('userName', this.ruleForm.userName) //用户姓名
          setCookie('sex', this.radio) //性别
          setCookie('SupplyrealAuth', true) //是否实名认证
          this.$router.push('/dashbord')
        }
      })
    },
  },
}
</script>
<style scoped>
.users {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.Main {
  width: 1200px;
  height: auto;
  height: 800px;
  margin-top: 100px;
  background: #ffffff;
}
.mb {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.mb span {
  display: inline-block;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #4d4e5d;
  margin: 20px 27px;
}
.user-ruleForm {
  margin-top: 80px;
}
.inputer {
  width: 350px;
}
.desc {
  width: 600px;
  height: 118px;
}
.avatar-uploader >>> .el-upload {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 138px;
  text-align: center;
  margin-top: -10px;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload__tip {
  line-height: 20px;
  margin-top: 10px;
}
.commitbtn {
  margin: 75px 0 0 280px;
}
.commitbtn a {
  display: inline-block;
  width: 218px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 50px;
  text-align: center;
  cursor: pointer;
}
</style>
